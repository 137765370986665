import React from 'react';
import './Contact.css'

const Contact = () => {
  return (
    <section id="contact" className="contact">
      <h2>Contact Us</h2>
      <p>Email: louiseschliemanninteriors@gmail.com</p>
      <p>Phone: +1 917 903 4960</p>
      <p>Address: 103 East 75th Street, New York</p>
    </section>
  );
};

export default Contact;
